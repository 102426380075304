.addList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    margin: auto;
    margin-bottom: 30px;
}
.addForm {
    background: #fff;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 8px;
    max-width: 500px;
    margin: auto;
    margin-bottom: 30px;
}
.addForm.dark {
    background: #191919;
}
.itemSubInfo {
    font-size: .8rem;
    color: #999;
    margin-right: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.itemModalInfo {
    max-width: 500px;
    margin: auto;
}
.itemModalInfo h5 {
    font-weight: 400;
    margin-bottom: 5px;
}
.soonShoplist {
    background: #fff;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    padding: 4px;
    border-radius: 8px;
    max-width: 500px;
    margin: auto;
    margin-top: 30px;
}
.soonShoplist.dark {
    background: #202020;
}
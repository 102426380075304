@keyframes returnModalClose {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(1.05);
    }
    20% {
        transform: scale(1);
    }
    30% {
        transform: scale(1.05);
    }
    40% {
        transform: scale(1);
    }
}
.myModal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.3);
    transition: all 0.2s;
}
.myModal .content {
    background: white;
    color: #000;
    border-radius: 8px;
    min-width: 250px;
    transform: scale(.1);
    transition: all 0.3s;
}
.myModal.dark .content {
    background: #202020;
    color: #ccc;
}
@media (max-width: 425px) {
    .myModal .content.normal {
        max-width: 90%;
    }
}
.myModal.active .content {
    transform: scale(1);
}
.myModal.active {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 10000;
}
.myModal.active.returned .content {
    animation: returnModalClose 2s;
}
.myModal.closed {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: -1;
}
.myModal .header {
    width: 100%;
    min-height: 63px;
    padding: 1rem;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.myModal .header span {
    max-width: 500px;
}
.myModal.dark .header {
    border-bottom: 1px solid #303030;
}
.myModal.dark .footer {
    border-top: 1px solid #303030;
}
.myModal .children {
    max-height: calc(90vh - 129px);
    overflow-y: auto;
    padding: 1rem;
}
.myModal .footer {
    max-width: 532px;
    border-top: 1px solid #ccc;
    padding: 1rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.myModal .footer button {
    width: 100%;
    max-width: 100%;
}
.myModal .footer.f2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.myModal.closed .content.closed {
    visibility: hidden;
}
.myModal .big {
    min-width: 500px;
}
@media (max-width: 600px) {
    .myModal .big {
        min-width: 90%;
        max-width: 90%;
    }
}
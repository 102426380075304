.bullet {
    list-style: none;
    counter-reset: li;
    padding: 0;
    max-width: 500px;
    margin: auto;
}
.bullet a {
    text-decoration: none;
}
.bullet.clickable li {
    cursor: pointer;
}
.bullet li {
    position: relative;
    margin-bottom: 1.5em;
    padding: 0.6em;
    border-radius: 4px;
    background: #fff;
    color: #231F20;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    transition: all 0.3s;
}
.bullet.bullet.clickable li:hover {
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 20%);
}
.bullet.dark li {
    background: #191919;
    color: #fff;
}

.bullet li:before {
    position: absolute;
    top: -0.7em;
    left: -0.5em;
    padding-left: 0.4em;
    padding-right: 0.4em;
    font-size: 16px;
    font-weight: bold;
    color: #4361ee;
    background: inherit;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    counter-increment: li;
    content: counter(li);
    box-shadow: 0 -2.5px 5px 0.5px rgba(0, 0, 0, 0.2);
}
.bullet li:nth-child(n+100):nth-child(-n+999):before {
    width: 35px;
    height: 35px;
}
.bullet li:nth-child(n+100):nth-child(-n+999) {
    padding-left: 1.65em;
}
@media (max-width: 510px) {
    .bullet {
        max-width: 90%;
    }
}
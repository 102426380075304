.myBtn {
    padding: 5px 15px;
    color: #4361ee;
    font-size: 14px;
    background: transparent;
    border: 1px solid #4361ee;
    cursor: pointer;
    transition: all 0.2s;
}
.red {
    color: red;
}
.myBtn.red {
    color: red;
    border: 1px solid red;
}
.myBtn:hover, .myBtn:active {
    background: #4361ee;
    color: white;
}
.myBtn.red:hover, .myBtn.red:active {
    background: red;
    color: white;
}
.myBtn:active {
    transform: scale(0.9);
}
.myBtn:disabled:hover {
    cursor: not-allowed;
    background: transparent;
    color: #4361ee;
}
.myBtn.red:disabled:hover {
    color: red;
}
.myBtn:focus-visible, .myBtn:focus-within, .myBtn:focus {
    outline: none;
}
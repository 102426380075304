.list {
    background: #fff;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    max-width: 500px;
    margin: auto;
    margin-top: 30px;
    padding: 8px;
    border-radius: 8px;
}
.list.dark {
    background: #202020;
}
@keyframes highlight {
    0% {
        background: rgba(100, 100, 100, 0.1)
    }
    50% {
        background: rgba(100, 100, 100, 0.2);
    }
    100% {
        background: rgba(100, 100, 100, 0.1);
    }
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.input {
    padding: 5px 15px;
    margin: 5px 0;
    border: 1px solid #4361ee;
    color: #444;
    background: rgba(0, 0, 0, 0);
    transition: all 0.2s;
}
.input::selection {
    background: rgb(255, 251, 0);
}
.input.dark::selection {
    background: #868d29;
}
.input:focus {
    outline: none;
}
.input.dark {
    color: #fff;
}
.input.disabled {
    color: #ccc;
    border: 1px solid #b4bada;
    cursor: not-allowed;
}
.input.disabled::selection {
    background: transparent;
}
.input.disabled.dark {
    border: 1px solid #394061;
}
.input.highlight {
    animation: 500ms highlight;
    animation-iteration-count: 3;
}
.list {
    background: #fff;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    padding: 8px;
    border-radius: 8px;
    margin-top: 25px;
}
.list.dark {
    background: #202020;
}
.itemSubInfo {
    font-size: .8rem;
    color: #555;
    margin-right: 5px;
}
.itemSubInfo.dark {
    color: #888;
}
.inp {
    border: none;
    outline: none;
    background: #fff;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 8px;
    text-align: center;
    cursor: pointer;
    max-width: 100%;
}
.inp.dark {
    background: #191919;
    color: #fff;
}
.project {
    text-align: center;
}
.circle {
    background: transparent;
    border: 3px solid #06d6a0;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 10px;
    text-decoration: none;
    cursor: pointer;
}
.circle span {
    font-size: 32px;
    color: #4361ee;
    transition: all 200ms;
}
.circle:hover span {
    transform: scale(1.2);
}
.title {
    font-weight: 500;
    font-size: 1.2rem;
    color: #444;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: all 200ms;
}
.title:hover {
    color: #222;
}
.title.dark {
    color: #ccc;
}
.title.dark:hover {
    color: #fff;
}
.line {
    border-bottom: 5px solid #4361ee;
    width: 70px;
    margin: auto;
    margin-top: 15px;
    border-radius: 8px;
    transition: all 200ms;
}
.title:hover .line {
    width: 120px;
}
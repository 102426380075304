.image {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0px 5px 10px 2px rgb(0, 0, 0, 0.3);
    transition: all 0.2s;
}
.image:hover {
    transform: scale(1.05);
}
.title {
    text-align: center;
}
.title a {
    color: #444;
    text-decoration: none;
}
.title.dark a {
    color: #fff;
}
.image {
    border-radius: 8px;
    max-width: 100%;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
}
.link {
    color: inherit;
    text-decoration: none;
}
.link:hover {
    color: inherit;
}
.instructions {
    border-radius: 8px;
    padding: 8px;
    max-width: 700px;
    margin: auto;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
}
.instructions.dark {
    background: #191919;
}
.instructions span {
    font-size: 20px;
}
.instruction {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.userRatings {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.userRatings div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
}
.userRatings div span {
    margin-right: 10px;
}
.userRatings div:nth-child(2) {
    margin: 0 10px;
}
.userRatings div:nth-child(2) span {
    margin: 0;
}
.sampleel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    margin-top: 15px;
}
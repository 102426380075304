.own {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.select {
    background: #fff;
    color: #444;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 8px 25px 8px 8px;
    cursor: pointer;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.select.dark {
    background: #191919;
    color: #fff;
}

.select::after {
    content: "";
    position: absolute;
    margin-left: 7.5px;
    margin-top: 7.5px;
    width: 7.5px;
    height: 7.5px;
    border-top: 2px solid #444;
    border-left: 2px solid #444;
    transform: rotate(225deg);
    transition: all .3s;
}

.select.dark::after {
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
}

.show .select::after {
    transform: rotate(45deg);
    margin-top: 10px;
}

.select + .label {
    z-index: -1;
    opacity: 0;
    cursor: default;
    border-radius: 8px;
    padding: 8px;
    background: #fff;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    transition: all .3s;
    position: absolute;
    top: 40px;
}

.select.dark + .label {
    background: #191919;
}

.show .label {
    z-index: 3;
    opacity: 1;
    top: 60px;
}
.label * {
    background: rgb(243, 243, 243);
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    transition: all .3s;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.label *:hover {
    background: rgb(220, 220, 220);
}
.select.dark + .label * {
    background: #161616;
}
.select.dark + .label *:hover {
    background: #131313;
}
.label *:not(:first-child) {
    margin-top: 10px;
}
.label * * {
    background: inherit;
    font-size: 16px;
    padding: 0;
    transition: all 0s;
}
.paramName {
    color: #444;
}
.weatherData {
    font-weight: 500;
    font-size: 1.2rem;
}
.paramName.dark {
    color: rgb(133, 133, 133);
}
.image {
    max-width: 50px;
}
.line {
    border-bottom: 5px solid #4361ee;
    width: 70px;
    margin: auto;
    margin-top: 15px;
    border-radius: 8px;
    transition: all 200ms;
}
.weather {
    background-color: rgb(255, 255, 255);
    color: #444;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 8px;
    max-width: 500px;
    margin: auto;
    transition: all 200ms;
}
.weather.dark {
    background: #191919;
    color: #ccc;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
}
.weatherTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.temp {
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.city {
    font-size: 2rem;
}
.isDark {
    outline: none;
    border: none;
    min-width: 200px;
    min-height: 200px;
    margin-right: 10px;
    border-radius: 8px;
    background: #181818;
    color: #fff;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    cursor: pointer;
    transition: all 0.2s;
}
.isDark:hover:not(:disabled) {
    background: #121212;
    transform: translateY(-5px);
    box-shadow: 0px 5px 10px 2px rgb(0, 0, 0, 1);
}
.isLight {
    outline: none;
    border: none;
    min-width: 200px;
    min-height: 200px;
    margin-left: 10px;
    border-radius: 8px;
    background: #f7f7f7;
    color: #000;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    cursor: pointer;
    transition: all 0.2s;
}
.isLight:hover:not(:disabled) {
    background: #fff;
    transform: translateY(-5px);
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 20%);
    justify-content: center;
}
.isDark.dark:not(:disabled) {
    box-shadow: 0px 5px 10px 2px rgba(105, 105, 105, 10%);
}
.isDark.dark:hover:not(:disabled) {
    box-shadow: 0px 5px 10px 2px rgba(105, 105, 105, 20%);
}
.isLight.dark:not(:disabled) {
    box-shadow: 0px 5px 10px 2px rgba(105, 105, 105, 10%);
}
.isLight.dark:hover:not(:disabled) {
    box-shadow: 0px 5px 10px 2px rgba(150, 150, 150, 1);
}
.isDark:hover:active:not(:disabled), .isLight:hover:active:not(:disabled) {
    transform: scale(0.95);
}
.isDark:hover:disabled, .isLight:hover:disabled {
    cursor: not-allowed;
}
@media (max-width: 465px) {
    .isDark, .isLight {
        min-width: 150px;
        min-height: 150px;
    }
}
@media (max-width: 390px) {
    .isDark, .isLight {
        min-width: 140px;
        min-height: 140px;
    }
}
@media (max-width: 370px) {
    .isDark, .isLight {
        min-width: 120px;
        min-height: 120px;
    }
}
@media (max-width: 320px) {
    .isDark, .isLight {
        min-width: 115px;
        min-height: 115px;
    }
}
@keyframes highlight {
    0% {
        background: rgba(100, 100, 100, 0.1)
    }
    50% {
        background: rgba(100, 100, 100, 0.2);
    }
    100% {
        background: rgba(100, 100, 100, 0.1);
    }
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.input {
    padding: 10px 15px;
    margin: 10px 0;
    border: 1px solid #4361ee;
    color: #444;
    background: rgba(0, 0, 0, 0);
    width: 100%;
    transition: all 0.2s;
}
.input::selection {
    background: rgb(255, 251, 0);
}
.input.dark::selection {
    background: #868d29;
}
.input:focus {
    outline: none;
}
.input.dark {
    color: #fff;
}
.input.disabled {
    color: #ccc;
    border: 1px solid #b4bada;
    cursor: not-allowed;
}
.input.disabled::selection {
    background: transparent;
}
.input.disabled.dark {
    border: 1px solid #394061;
}
.input.highlight {
    animation: 500ms highlight;
    animation-iteration-count: 3;
}
.group {
    position: relative;
}
.placeholder {
    position: absolute;
    left: 15px;
    top: calc(50% - 0.75em);
    pointer-events: none;
    padding: 0;
    font-size: 1rem;
    transition: top 250ms, left 250ms, right 250ms, bottom 250ms, font-size 250ms, padding 250ms;
}
.input:focus + .placeholder, .active {
    top: calc(-50% + 2rem);
    padding: 0 5px;
    left: 5px;
    font-size: .8rem;
}
.navbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 100vw;
    height: 56px;
    background: rgba(255, 255, 255, 0.6);
    color: #444;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    z-index: 2;
}
.navbar.dark {
    background: rgba(0, 0, 0, 0.2);
    color: #ccc;
}

.router {
    margin-top: 10px;
}

.navbar a,
.navbar .router Link,
.navbar .router span {
    color: #444;
    text-decoration: none;
    margin-left: 7.5px;
    transition: all 200ms;
}

.navbar a:hover,
.navbar .router Link,
.navbar .router span:hover {
    color: #000;
}

.navbar .router span:hover {
    color: #000;
}

.navbar.dark a,
.navbar.dark .router Link {
    color: rgb(236, 236, 236);
}

.navbar.dark a:hover,
.navbar.dark .router Link:hover {
    text-shadow: 0px 0px 5px rgb(255, 255, 255);
    color: #fff;
}

.navbar a:first-child {
    font-size: 1.25rem;
    margin-left: 10px;
    margin-right: 10px;
}

.info {
    position: absolute;
    right: 10px;
    top: 5px;
}

.currentInfo {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
}

.weather {
    font-size: 2rem;
}

@media (max-width: 450px) {
    .weather {
        display: none;
    }
}

.filler {
    margin-bottom: 56px;
}
.settings {
    max-width: 500px;
    margin: auto;
    box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
    padding: 8px;
    border-radius: 8px;
}
.settings.dark {
    background: #181818;
    border-radius: 0 0 8px 8px;
}
.themes {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.permission {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
}
.permission span {
    margin-right: 20px;
}
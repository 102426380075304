.link {
    text-decoration: none;
    color: #fff;
    text-align: center;
}
.link:hover {
    color: #fff;
}
.weather {
    text-align: center;
}